<template>
  <div>
    <v-card flat class="mt-3">
      <v-row no-gutters>
        <v-col
          class="mt-3"
          v-if="$vuetify.breakpoint.name != 'xs'"
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xl="12"
        >
          <v-toolbar dense elevation="0">
            <v-select
              v-model="shiftName"
              label="Select Shift"
              dense
              outlined
              :disabled="isLoading == true"
              :items="ShiftItems"
              item-text="workshift_name"
              item-value="workshift_id"
              style="max-width: 220px"
              @change="get_daily_absent_report()"
              class="mt-5"
            ></v-select>
            <v-dialog
              ref="dialog"
              v-model="modal"
              color="#f4813f"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  outlined
                  class="mt-5 ml-2"
                  persistent-hint
                  :disabled="isLoading == true"
                  label="Filter by Date"
                  color="#f4813f"
                  style="max-width: 180px"
                  append-icon="mdi-calendar"
                  @click:append="date ? getcal() : getcal()"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="minmonth"
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="#f4813f"
                  v-on:click="get_daily_absent_report()"
                  @click="$refs.dialog.save(date)"
                >
                  ok
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-text-field
              label="Search"
              class="mt-5 ml-2"
              v-model="search"
              style="max-width: 220px"
              dense
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="3">
          <v-toolbar dense elevation="0">
            <v-spacer v-if="$vuetify.breakpoint.name != 'xs'"></v-spacer>
            <v-select
              v-model="shiftName"
              label="Select Shift"
              dense
              outlined
              :items="ShiftItems"
              item-text="workshift_name"
              item-value="workshift_id"
              @change="get_daily_absent_report()"
              class="mr-2 mt-5"
            ></v-select>
            <v-dialog
              ref="dialog"
              v-model="modal"
              color="#f4813f"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  outlined
                  class="mt-5 mr-2"
                  persistent-hint
                  :disabled="isLoading == true"
                  label="Filter by Date"
                  color="#f4813f"
                  append-icon="mdi-calendar"
                  @click:append="date ? getcal() : getcal()"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="minmonth"
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="#f4813f"
                  v-on:click="get_daily_absent_report()"
                  @click="$refs.dialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="3">
          <v-toolbar dense elevation="0">
            <v-text-field
              label="Search"
              class="mt-5 mr-2"
              v-model="search"
              dense
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="absentData"
          :items="absentItems"
          dense
          :search="search"
          :height="height"
          :items-per-page="100"
          :loading="isLoading"
          :fixed-header="fixed"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text"
              >No Absent / swipe data available
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.cont_number`]="{ item }">
            <span
              >{{
                item.user_country_code == undefined
                  ? ""
                  : item.user_country_code
              }}
              -
              {{
                item.user_contact_number == undefined
                  ? ""
                  : item.user_contact_number
              }}</span
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { GetAbsentReport } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import { Getshiftwork } from "@/mixins/GetWorkshift.js";

export default {
  components: {
    Overlay,
  },
  mixins: [Getshiftwork],
  data: (vm) => ({
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    height: 0,
    isLoading: false,
    fixed: true,
    overlay: false,
    search: "",
    absentData: [
      { text: "Member Id", value: "employee_id" },
      { text: "Member Name", value: "user_name" },
      { text: "Contact Number", value: "cont_number" },
    ],
    absentItems: [],
    ShiftItems: [],
    shiftName: "ALL",
    minmonth: "",
    selectedDate: "",
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  async created() {
    this.height = window.innerHeight - 212;
    this.overlay = true;
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );

    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    await this.Getshiftwork();
    await this.fetchdetails();
    await this.get_daily_absent_report();
  },
  methods: {
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    fetchdetails() {
      this.GetWorkshiftitems.forEach((element) => {
        this.ShiftItems.push({
          workshift_name: element.workshift_name,
          workshift_id: element.workshift_id,
        });
      });
      this.ShiftItems.unshift("ALL");
    },
    async get_daily_absent_report() {
      this.isLoading = true;
      this.overlay = true;
      try {
        this.absentItems = [];
        await API.graphql(
          graphqlOperation(GetAbsentReport, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              swipe_time_stamp: new Date(this.date).getTime() / 1000,
              workshift_id: this.shiftName,
            },
          })
        ).then((res) => {
          this.absentItems = JSON.parse(res.data.GetAbsentReport).data;
        });
        this.isLoading = false;
        this.overlay = false;
      } catch (err) {
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        console.log("error", err);
        this.isLoading = false;
        this.overlay = false;
      }
    },
    getcal() {
      this.modal = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
</style>
